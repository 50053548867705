ion-item{
    text-align: center;
 
}



.catTitle{
    font-weight: 800;
    border-bottom: 1px solid blue;
    padding-top: 15px;
    margin-bottom: 12px;

}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .selected {
    --background: #f4f4f8; /* Een zeer lichte grijstint voor de achtergrond */
    --border-color: #dcdcdc; /* Een lichte randkleur */
    border-right: 4px solid var(--ion-color-primary); /* Een duidelijke markering aan de linkerkant */
    color: var(--ion-color-primary); /* Tekstkleur voor het geselecteerde item */
    transition: background-color 0.3s, color 0.3s; /* Een zachte overgang voor de achtergrond en tekstkleur */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }

  .selected:hover {
    --background: #e9e9ef; /* Een iets donkerdere tint bij hover */
  }
  

  .actionstoolbar ion-button{
    height: 40px;
 
  }

  .actionstoolbar .subactions ion-button:first-child {
    margin-right: 15px;
  }

  .actionstoolbar .subactions  ion-button:not(:first-child):not(:last-child) {
    margin-right: 3px;
  }

  .custom-buttons ion-button:last-child {
    margin-right: 0;
  }


  .spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .idname{
   
    font-weight: 700;
  }

 

  .adres{
    padding-top:10px;
    width: 250px;
    padding-bottom:10px;
  }

  .orderitem{
    display: flex;
    padding-left: 0;
  }

  .statusicon{
    display: flex; 
    padding-top:0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .itemicon{
    font-weight:900;
    font-size: 15px;
    padding-top:5px;
  } 

  .orderamount{
    font-weight:900;
    font-size: 16px;
    width:27px;
    align-items: center;
    justify-content: center;
    padding-top:0px;
    display:flex;
  }

  @media (max-width: 1500px) {
    .adres{
   
      width: 220px;
      font-size: 12px;
    }
    .orderamount{
      font-weight:900;
      font-size: 12px;
      width:60px;
      align-items: center;
      justify-content: center;
      padding-top:0px;
      display:flex;
    }

    .itemicon{
      font-weight:900;
      width: 18px;
      display:flex;
      align-items: center;
      justify-content: center;
      padding-top:5px;
      
    } 
  }


  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .active{
    border:1px solid;
  }


  :host( ion-item) {
    --padding-start: 0px !important;
  }

  ion-item::part(item) {
    --padding-start: 0;
  }

  .timerStyle {
    --padding: 15px;
    --border-radius: 8px;
    --border-width: 2px;
    --font-family: var(--ion-font-family, "Helvetica Neue", sans-serif);
    --font-size: 1rem;
    --transition: background-color 0.5s, color 0.5s;
  }
  

.normalStyle {
  background-color: transparent; /* Geen achtergrond */
  color: var(--ion-text-color, #000); /* Standaard tekstkleur */
  padding: var(--padding);
  border-radius: var(--border-radius);
  border: none; /* Geen rand */
  font-family: var(--font-family);
  font-size: 1rem; /* Standaard tekstgrootte */
  font-weight: normal; /* Standaard tekstgewicht */
  transition: color 0.5s ease-out;
}
  

  
  /* Timer stijl voor wanneer de tijd op is */
  .timeUpStyle {
    background-color: var(--ion-color-danger, #f1453d); 
    color: var(--ion-color-success-contrast, #fff);
    padding: var(--padding);
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--ion-color-danger-shade, #ff0000);
    font-family: var(--font-family);
    font-size: var(--font-size);
    transition: var(--transition);
  }
  
  /* Timer stijl voor ongeldige datum/tijd */
  .invalidDateTimeStyle {
    background-color: var(--ion-color-medium, #92949c);
    color: var(--ion-color-medium-contrast, #fff);
    padding: var(--padding);
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--ion-color-medium-shade, #808289);
    font-family: var(--font-family);
    font-size: var(--font-size);
    transition: var(--transition);
  }

/* Timer stijl voor wanneer er nog 1 minuut over is, enkel tekst */
.oneMinuteStyle {
  background-color: transparent; /* Geen achtergrond */
  color: var(--ion-color-danger, #f1453d); /* Accentkleur voor urgentie */
  padding: var(--padding);
  border-radius: var(--border-radius);
  border: none; /* Geen rand */
  font-family: var(--font-family);
  font-size: 1.2rem; /* Iets grotere tekst */
  font-weight: bold; /* Vetgedrukte tekst */
  
  transition: color 0.5s ease-out;
  animation: pulseText 2s infinite;
}

/* Subtiele pulse animatie voor de tekstkleur in .oneMinuteStyle */
@keyframes pulseText {
  0%, 100% {
    color: var(--ion-color-danger, #eec0be);

  }
  50% {
    color: var(--ion-color-danger-tint, #ff0000);
    
  }
}

.order-detail-container {
  display: flex;
  justify-content: space-between;
}

.order-info, .order-status-changes {
  flex: 1; /* Zorgt ervoor dat beide kolommen evenveel ruimte innemen */
  margin: 0 10px; /* Geeft wat ruimte tussen de kolommen */
}

/* Je kunt extra stijlen toevoegen voor de lijst van statuswijzigingen */
.order-status-changes ul {
  list-style: none;
  padding: 0;
}

.order-status-changes li {

  margin-bottom: 5px;

  border-radius: 5px;
}
